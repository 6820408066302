.events-container {
  min-height: 368px;
  width: 380px;
  border-radius: 12px;
  margin-top: 10px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.events-Heading {
  font-size: 25px;
  color: black;
  text-align: center;
  padding: 5px 5px;
  width: 100%;
}

.events-card {
  width: 98%;
  height: 64px;
  font-size: 14px;
  border-radius: 6px;
  padding: 5px;
  color: #303030;
  border: 1px solid #e0e0e0;
  list-style-type: none;
  margin: 8px auto 10px auto;
  /* background-color: #eb4141; */
  display: flex;
}

.events-add-btn {
  /* background-color: #00adee; */
  background-color: #f45c2c;
  border: none;
  cursor: pointer;
  margin-right: 10px;
}

@media screen and (max-width: 568px) {
  .birthday-container {
    width: 300px;
  }
}

.event-error {
  border-left: 10px solid red;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  outline: none;
}

.input-element {
  width: 100%;
}

.date-picker {
  width: 100%;
}

.event-name {
  /* width: 60%;
  margin-right: 45px;
  background-color: #f45c2c; */
  width: 70%;
  margin-right: 10px;
  /* overflow: auto; */
  /* display: flex;
  flex-wrap: wrap; */
}

.event-name::-webkit-scrollbar {
  display: none;
}

.events-list::-webkit-scrollbar {
  display: none;
}

/* .events-name::-webkit-scrollbar {
  display: none;
} */

.events-display-card {
  position: relative;
  /* background-color: #e0e0e0; */
}

.event-delete-icon {
  position: absolute;
  top: -8px;
  right: 1px;
  /* z-index: 10; */
  cursor: pointer;
  font-size: 18px;
}

.events-list {
  overflow-y: auto;
  height: 258px;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
}
