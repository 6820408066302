.popup-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.popup-content {
  background-color: white;
  padding: 10px 15px 15px 0px;
  border-radius: 8px;
  width: 680px;
  /* height: fit-content; */
}

.add-employee-form {
  width: 100%;
}

.close-icon-container {
  width: 100%;
}

#employeeID {
  text-transform: uppercase;
}
.submit-btn {
  /* background-color: #003480; */
  border: 0px none;
  color: white;
  display: block;
  width: 100px;
  height: 35px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  height: 35px;
}

.select-element {
  width: 260px;
  height: 35px;
  outline: none;
  border-radius: 5px;
  background-color: white;
  border: 1px solid grey;
  padding-left: 5px;
}

@media (min-width: 768px) {
  .add-employee-form-container {
    width: 659px;
    max-height: 650px;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    z-index: 2;
  }
}

@media screen and (max-width: 767px) {
  .add-employee-form-container {
    width: 300px;
    height: 500px;
    /* overflow-y: auto; */
  }

  .popup-content {
    width: 350px;
  }
}

/* .input {
  outline: none;
  border-radius: 5px;
  border: 1px solid;
  height: 35px;
  width: 260px;
} */

.input-element-container {
  margin-right: 40px;
}

.dropdown-button {
  background-color: transparent;
  border: 1px solid grey;
  width: 260px;
  height: 35px;
  border-radius: 5px;
  margin-top: 20px;
}

.highlight-input-error {
  border-left: 10px solid red;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 260px;
  height: 35px;
  border-radius: 5px;
  outline: none;
}

.form-input {
  width: 260px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid grey;
  font-size: 16px;
}

.dropdown-btn {
  width: 320px;
  background-color: transparent;
  /* border: 1px solid #e86020; */
  border-radius: 5px;
  height: 40px;
}

.certificates-dropdown {
  list-style-type: none;
  margin-left: 0px;
  padding-left: 10px;
}

.add-emp-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.password-container {
  position: relative;
  margin-top: 0px;
  padding: 0px;
}

.password-eye-icon {
  position: absolute;
  top: 12px;
  right: 5px;
}
