.small-text{
    font-size: 16px;
}
.form-div-container{
    width: 100%;
}
.input-width{
    width: 100%;
}
 .label-elements{
    font-size: 18px !important;
  }