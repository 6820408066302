.add-skill-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 20px; */
}
.addSkillBtn {
  background-color: transparent;
  border: none;
  text-align: left;
  color: "#959090";
  font-size: 14px;
}
