.display-bill {
  width: 100%;
  height:450px;
}

.billAmount {
  text-align: right;
  margin-right: 5%;
  font-size: 20px;
  margin-top: 10px;
}

.total-amount {
  font-weight: 600;
}

.popup-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.display-bill-modal {
  background-color: white;
  padding: 10px 15px 15px 25px;
  border-radius: 8px;
  width: 700px;
  height: 700px;
  max-height: 600px;
  scroll-behavior: auto;
}



/* .display-bill-modal {
  width: 50%;
  height: 70%;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
} */
