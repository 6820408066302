.Upload-loader {
    display: flex;
    align-items: center;
  }
  
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    animation: spin 1s linear infinite;
    stroke: rgba(107, 114, 128, 1);
  }
  
  .loading-text {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    color: rgba(107, 114, 128, 1);
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }