.popup-content-profile {
  /* background-color: aliceblue; */
  background-color: white;
  padding: 10px 25px 25px 25px;
  box-shadow: 0px 0px 2px 4px aliceblue;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  margin-right: 2%;
  /* position:absolute; */
}

.header-container {
  display: flex;
  align-items: center;
  /* background-color: aliceblue; */
  /* background-color: #e8e3e3; */
  min-width: 375px;
  width: 100%;
  margin-top: 0px;
  height: 60px;
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  top: 0%;
  position: sticky;
  z-index: 5;
}

.profile-icon-container {
  color: white;
  font-size: 22px;
  font-weight: bold;
  background-color: #f45c2c;
  border: 0px none;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-container {
  position: relative;
  padding: 10px;
  margin-right: 15px;
  cursor: pointer;
}

.notification-btn {
  background-color: transparent;
  border: none;
}

.notification-icon {
  font-size: 25px;
  /* color: #00adee; */
}

.notification-count {
  position: absolute;
  top: 8px;
  right: 0px;
  color: white;
  background-color: red;
  border-radius: 50%;
  height: 20px;
  min-width: 20px;
  max-width: 25px;
  box-shadow: border-box;
  text-align: center;
  font-size: 12px;
}

.nav-container {
  position: relative;
}

.notification-list-container {
  position: absolute;
  right: 50px;
  top: 60px;
  background-color: white;
  padding: 15px;
  width: 400px;
  max-height: 600px;
  overflow-y: auto;
  list-style-type: none;
  border-radius: 8px;
}

.notification-list-container::-webkit-scrollbar {
  display: none;
}

.close-icon-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  padding-bottom: 0px;
  height: 25px;
}

.notify-close-btn {
  background-color: transparent;
  color: red;
  border: none;
  font-size: 15px;
  margin: 0px;
  padding: 0px;
}

.mobile-hamberger-menu {
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  cursor: pointer;
}

@media all and (min-width: 768px) {
  .mobile-hamberger-menu {
    display: none;
  }
}

.company-logo-header {
  height: 55px;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  margin-bottom: 15px;
}

.notification-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
