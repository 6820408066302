/* Styling for the skills search bar */
.skills-search-bar {
  width: 100%;
  padding: 8px 12px;
  margin-bottom: 15px;
  font-size: 14px;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}

.skills-search-bar:focus {
  border-color: #00adee;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 173, 238, 0.5);
}

/* Styling for skills list container */
.skills-list-container {
  padding-left: 0;
  list-style-type: none;
  margin: 0;
  max-width: 520px;
  
 
}

.skills-list-item {
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin: 5px 0;
  cursor: pointer;
  max-width: 350px;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.skills-list-item:hover {
  background-color: #e2f7fe;
  transform: scale(1.05);
  box-shadow: 0 4px 10px rgba(0, 173, 238, 0.2);
}

/* Progress bar and rating */
.progress-bar {
  transition: width 0.5s ease-in-out;
}

.bg-success {
  background-color: #4f51ca;
}

.bg-danger {
  background-color: #f44336;
}

/* Styling for the modal content */
.skills-modal-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-width: 400px;
  margin: 0 auto;
}

.skills-modal-content h3 {
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center;
  color: #333;
}

/* Smooth animation when the modal opens */
@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.skills-modal-content {
  animation: slideIn 0.3s ease-out;
}
.profile-box {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f0f0f0;
  cursor: pointer;
  overflow: hidden; /* Ensures content stays within the circle */
}

.profile-image {
  width: 100%; 
  height: 100%; 
  object-fit: cover; 
}


.profile-hover-box {
  position: absolute;
  width: 300px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transform-origin: top left;
  display: flex;
  animation: expandBox 0.3s ease-in-out;
  z-index: 100;
}

.hover-profile-image {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

.hover-profile-name {
  font-size: 20px; /* Slightly larger for better readability */
  font-weight: bold;
  margin-left: 20px;
  color: #333; /* Darker color for better contrast */
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth hover effect */
}

.hover-profile-name:hover {
  color: #4f51ca; /* Change color on hover */
  transform: translateX(5px); /* Subtle shift effect */
}

.hover-profile-designation {
  font-size: 14px;
  color: gray;
  margin-left: 20px;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out; /* Smooth hover effect */
}

.hover-profile-designation:hover {
  color: #4f51ca; /* Match hover color with name */
  transform: translateX(5px); /* Subtle shift effect */
}

@keyframes expandBox {
  from {
    transform: scale(0.9); /* Start slightly smaller */
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.profileAndDesignationStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Add better spacing between elements */
  align-items: flex-end;
  margin-left: auto;
  background: #f9f9f9; /* Subtle background for distinction */
  padding: 10px 15px; /* Add some padding for spacing */
  border-radius: 8px; /* Rounded corners for modern look */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  animation: fadeIn 0.5s ease-in-out; /* Smooth entry animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px); /* Start slightly below */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Slide into place */
  }
}
