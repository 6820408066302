.left-nav-bar {
  height: calc(100vh - 60px);
  background-color: #e86020;
  max-width: fit-content;
  border: 2px solid grey;
  padding-bottom: 0%;
  position: sticky;
  top: 60px;
  overflow-y: scroll;
  flex-grow: 1;
  flex-shrink: 1;
}

.left-navigation-container {
  position: relative;
}

.arrow-icon-container {
  position: absolute;
  top: -10px;
  right: -13px;
  font-size: 30px;
  padding: 0px;
  border-radius: 50%;
  margin-top: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.logout-modal-container {
  width: 500px;
  /* height: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.left-nav-bar::-webkit-scrollbar {
  width: 0; /* Remove scrollbar width */
  height: 0; /* Remove scrollbar height */
}

.nav-items-container {
  height: 80%;
  margin-left: 0px;
  padding-left: 0px;
  list-style-type: none;
  /* overflow-y: auto; */
  /* padding-top: 50px; */
}
.nav-items-container::-webkit-scrollbar {
  display: none;
}
.nav-items-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: fit-content;
  position: relative;
}
.container {
  padding-top: 150px;
}

.expand-menu {
  min-width: 250px;
}

.collapse-menu {
  width: 60px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .expand-menu {
    width: 250px;
  }

  .collapse-menu {
    min-width: 60px;
    overflow: hidden;
  }
  .large {
    display: none;
  }
}

.hamburger-icon {
  font-size: 30px;
  margin-bottom: 10px;
  cursor: pointer;
  color: white;
  /* position: absolute; */
}

.bottom-nav-container {
  list-style-type: none;
  padding: 0px;
}

/* .navitem-icon {
  color: white;
  font-size: 25px;
} */

.setting-nav-item {
  background-color: transparent;
  border: 0px none;
  font-size: 16px;
  width: 200px;
  /* color: white; */
  text-align: left;
}

.logout-list-item {
  background-color: transparent;
  color: white;
  font-size: 18;
  margin-left: 0px;
  padding-left: 10px;
  padding-top: 10px;
  height: 40px;
  margin-bottom: 8px;
}

.logout-list-item:hover {
  height: 40px;
  background-color: #ffffff80;
  color: black;
}

@keyframes mobile-slide-in {
  from {
    transform: translate(-100%);
  }
  to {
    transform: translate(0%);
  }
}

.settings-icon {
  color: white;
  font-size: 25px;
  margin-right: 30px;
}

.nav-item-icon {
  font-size: 30px;
  margin-right: 25px;
}

/* .navitem-icon :hover {
  color: black;
} */

@media all and (min-width: 767px) {
  .mobile-menu {
    display: none;
  }
}
.close-menu-div {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
}
.mobile-menu-close-icon {
  border: none;
  background: transparent;
  color: white;
  font-size: 26px;
}
.slideMobileMenu-enter {
  transform: translateX(-100%);
}

.slideMobileMenu-enter-active {
  transform: translateX(0%);
  transition: transform 0.3s ease-in;
}

.slideMobileMenu-exit {
  transform: translateX(0%);
}

.slideMobileMenu-exit-active {
  transform: translateX(-100%);
  transition: transform 0.3s ease-in;
}

.active-navItem {
  /* background-color: #00ADEE; */
  background-color: #e8e3e3;
  color: black;
}

.unactive-navItem {
  color: white;
}

/* new css */

.logout-btn-nav {
  background-color: transparent;
  border: 0px none;
  width: 200px;
  text-align: left;
  font-size: 16px;
}

.nav-list-item {
  margin-left: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
  font-size: 18px;
  box-sizing: content-box;
  padding-top: 10px;
  padding-left: 10px;
}

.settings-link {
  text-decoration: none;
}

.settings-active {
  color: black;
  background-color: #e8e3e3;
  height: 35px;
}

.settings-unactive {
  color: white;
  height: 35px;
}

.settings-link :hover {
  color: black;
  background-color: #e8e3e3;
  height: 35px;
}

.nav-icons {
  font-size: 25px;
  margin-right: 25px;
}

.logout-nav {
  color: white;
}

.logout-nav :hover {
  color: black;
  background-color: #e8e3e3;
}

