.heading {
  text-align: center;
  color: #003480;
  font-family: "Times New Roman", Times, serif;
  font-weight: 700;
  font-size: 24px;
  margin-left: auto;
}

.tailspin {
  height: 100vh;
}

.tabButtons {
  background-color: transparent;
  border: 0px none;
}

.activeTabButton {
  background-color: transparent;
  border: 0px none;
  border-bottom: 3px solid rgb(232, 96, 32);
  color: rgb(232, 96, 32);
}

.popup-overlay-profile {
  position: fixed;
  top: 10%;
  left: 80%;
  width: 20vw;
  height: 200px;
  /* background-color: rgba(0, 0, 0, 0.5);  */
  /*Semi-transparent overlay */
}

/* .popup-content {
  
  background-color: white;
  padding: 10px 25px 25px 25px;
  border-radius: 8px;
  width: 650px;
  height: 90vh;
} */

.popup-content-profile {
  background-color: white;
  padding: 10px 25px 25px 25px;
  box-shadow: 0px 0px 2px 4px aliceblue;
  border-radius: 8px;
  width: fit-content;
  height: fit-content;
  margin-right: 2%;
}

.popup-overlay-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-alert {
  background-color: white;
  padding: 10px 20px 10px 20px;
  border-radius: 8px;
}

.close-button {
  background-color: transparent;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 10px;
  display: block;
}
.left-container {
  margin-right: 35px;
}

.dateElement {
  width: 260px;
  outline: none;
  height: 35px;
  border-radius: 5px;
  border: 1px solid;
}

.labels {
  margin-bottom: 5px;
}

.address-input {
  outline: none;
  border: 1px solid;
  border-radius: 5px;
}

.table-container {
  margin: 10px;
}

.noData-img {
  width: 600px;
}

.search-input {
  outline: none;
  padding-left: 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid;
  height: 35px;
}

.search-input::placeholder {
  font-size: 16px;
}

.header-elements-right {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 50px;
}

.company-logo-header {
  width: 180px;
  margin-left: 50px;
}

.close-icon {
  color: red;
  font-size: 25px;
  font-weight: 600;
}

.add-employee-icon {
  font-size: 25px;
  color: white;
}

.add-employee-icon-btn {
  background-color: #00adee;
  border: 0px none;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 10px;
}

.logout-btn {
  background-color: transparent;
  border: 0px none;
  color: black;
  border-radius: 8px;
  font-size: 16px;
  height: 40px;
  margin-right: 20px;
}

.logout-btn:hover {
  color: #00adee;
}

.logout-icon-btn {
  background-color: #00adee;
  border: 0px none;
  height: 50px;
  width: 50px;
  border-radius: 50px;
  margin-right: 0px;
}

@media screen and (max-width: 767px) {
  .popup-content {
    width: 350px;
  }

  .input-element-container {
    margin-right: 0px;
  }

  .add-employee-btn {
    display: none;
  }

  .logout-btn {
    display: none;
  }
  .profile-icon-container {
    display: none;
  }
  .company-logo-header {
    width: 150px;
    margin-left: 8px;
  }
}

select.decorated option:hover {
  box-shadow: 0 0 10px 100px #1882a8 inset;
}

@media screen and (min-width: 768px) {
  .add-employee-icon {
    display: none;
  }
  .header-container {
    min-width: 375px;
    width: 100%;
  }
}

.all-employees-container {
  min-width: 375px;
}

.copy-icon {
  font-size: 18px;
  cursor: pointer;
}

.add-emp-invalid {
  color: red;
  margin-top: 10px;
  font-size: 16px;
}

/* .add-employee-btn {
  background-color: #00adee;
  color: white;
  height: 35px;
  width: fit-content;
  border: 0px none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
} */

.all-employees-page {
  width: 100%;
  background-color: #ededed;
}

.import-excel-container {
  background-color: white;
  width: 600px;
  padding: 20px;
  border-radius: 10px;
}

.upload-excel-label {
  background-color: #f45c2c;
  color: white;
  height: 35px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  padding: 10px;
  margin-right: 15px;
}

.delete-employee-modal {
  background-color: #ffffff;
  height: 250px;
  width: 500px;
  border-radius: 8px;
  padding: 15px 10px;
}

.deleteEmployeesContainer {
  background-color: #fb580133;
  padding-left: 30px;
  color: #212121;
  font-size: 16px;
  font-weight: 600;
  margin-left: 15px;
  margin-right: 15px;
}

.deleteIcon {
  font-size: 20px;
  background-color: transparent;
  margin-left: 20px;
  background-color: transparent;
  border: 0px none;
}

.deleteIcon:hover {
  cursor: pointer;
}

.dropdown-button {
  background-color: transparent;
  border: 1px solid red;
}
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  width: 200px;
  z-index: 1;
}

.dropdown-menu.open {
  display: block;
}
/* Dropdown container */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown toggle button */
.dropdown-toggle {
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border: none;
  margin: 6px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Change background on hover */
.dropdown-toggle:hover {
  background-color: #E8582A;
  
}

/* Dropdown menu container */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  width: 200px;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 1000;
}

/* Show dropdown when open */
.dropdown-menu.open {
  display: block;
  opacity: 1;
  transform: translateY(0);
}

/* Dropdown item styles */
.dropdown-item {
  padding: 10px;
  font-size: 14px;
  color: #333;
  text-decoration: none;
  display: block;
  transition: background-color 0.2s ease;
  border-bottom: 1px solid #f1f1f1;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
  background-color: #f1f1f1;
}
