.new-profile-page {
  width: 98%;
  margin: 10px;
  background-color: #ffffff;
  padding: 10px;
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  border-radius: 6px;
  min-height: 85%;
}

.profile-container {
  width: 100vw;
  /* margin: 10px 15px; */
  background-color: #ededed;
  /* padding: 10px; */
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  border-radius: 6px;
}

.profile-top-section {
  width: 100%;
  /* height: 380px; */
  border-radius: 10px;
  /* position: relative; */
  padding: 10px;
}

.cover-pic-container {
  position: relative;

}

.delete-cover-pic-btn {
  background-color: transparent;
  border: none;
  color: white;
  cursor: pointer;
}

.cover-pic-btns-container {
  position: absolute;
  bottom: 10px;
  right: 30px;
  font-size: 28px;
}

.camera-icon-btn {
  /* position: absolute;
  bottom: 10px;
  right: 30px; */
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: white;
}

.cover-pic {
  width: 50%;
  height: 230px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.coverpic-form {
  background-color: white;
  width: 70%;
  padding: 10px 10px;
  border-radius: 8px;
}

.profile-pic-container {
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  padding: 10px;
  border-radius: 4px;
}

.employeeName {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 0px;
}

.user-name-section {
  margin-left: 20px;
  width: 80%;
  color: #212121;
  font-size: 12px;
}

.tabs-container {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  flex-wrap: wrap;
  list-style-type: none;
  min-height: 50px;
  max-height: fit-content;
  margin-top: 10px;
}

.tab-highlight {
  /* background-color: #FFFFFF; */
  width: 150px;
  color: #78829d;
  font-weight: 600;
}

.user-info-container {
  /* border-radius: 10px;
  padding: 20px 45px;
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  margin: 0px 10px; */
  padding: 20px 45px;
}

.edit-profile-btn {
  border: 0px;
  border-radius: 8px;
  width: 100px;
  background-color: #00adee;
  color: white;
  font-weight: 600;
}

.submit-btn:disabled {
  cursor: not-allowed;
  background-color: #e0e0e0;
  color: #999999;
}

.camera-icon {
  background-color: #ffffff;
  border: 0px none;
  box-shadow: 0px 1px 4px 0px #00000040;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.profileImageContainer {
  position: relative;
}
.profile-image {
  height: 96px;
  width: 96px;
  border-radius: 50%;
  object-fit: cover;
}

.bottom-container {
  background-color: #ffffff;
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  padding: 16px, 10px, 16px, 10px;
}
