.holidays-list-container {
  width: 500px;
  padding: 15px 8px 10px 15px;
  border-radius: 8px;
  margin-top: 2rem;
  height: 400px;
  position: relative;
  /* background-color: green; */
}

.holidays-list-content-loader {
  padding: 15px 8px 10px 15px;
  border-radius: 8px;
  height: 400px;
}

.edit-icon {
  cursor: pointer;
  background-color: #f45c2c;
  border: none;
  height: 25px;
}

.holidays-ul-list {
  padding-left: 0px;
  width: 100%;
  overflow-y: auto;
  height: 350px;
}
.holidays-ul-list::-webkit-scrollbar {
  display: none;
}

.holiday-item {
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 10px;
}

.ocassion-name {
  width: 65%;
  height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
}

.ocassion-date {
  width: 120px;
}

.occassion-day {
  width: 120px;
}

@media screen and (max-width: 767px) {
  .holidays-list-container {
    width: 300px;
    flex: grow 1;
  }
}

.addHolidays-modal {
  background-color: white;
  min-height: 300px;
  width: 500px;
  padding: 20px;
  border-radius: 10px;
}

.input-element {
  width: 100%;
}

.submit-btn {
  width: 100%;
  margin-top: 15px;
}

.cross-icon {
  background-color: transparent;
  border: none;
  color: red;
}

.holidays-card {
  width: 98%;
  height: fit-content;
  font-size: 14px;
  border-radius: 6px;
  padding: 5px;
  color: #303030;
  border: 1px solid #e0e0e0;
  list-style-type: none;
  margin: 0px auto 10px auto;
  display: flex;
  /* align-items: center; */
  position: relative;
}

.holiday-display-card {
  position: relative;
  margin-top: 7px;
  height: fit-content;
}
.holiday-delete-icon {
  position: absolute;
  top: -8px;
  right: 1px;
  /* z-index: 10; */
  cursor: pointer;
  font-size: 18px;
}

.holidays-list {
  overflow-y: auto;
  height: 258px;
  margin-top: 0px;
  margin-left: 0px;
  padding-left: 0px;
}

.holiday-name {
  width: 70%;
  margin-right: 0px;
}
