.leave-cancel-btn {
  background-color: transparent;
  border: 1px solid #e86020;
  width: 126px;
  height: 28px;
  margin-top: 5px;
  border-radius: 6px;
  color: #e86020;
  font-weight: 600;
  font-size: 14px;
}

.history-container {
  /* height: 50vh; */
  font-family: Arial, Helvetica, sans-serif;
}

.date-input-label {
  font-size: 16px;
  height: 35px;
  width: 155px;
  border-radius: 4px;
  border: 1px solid #000000;
  color:#000000;
  font-weight: 500;
  outline: none;
}

.cancel-leave-div {
  margin-top: 10px;
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submit-cancel-btn {
  margin-top: 15px;
  text-align: center;
}

.cancel-input {
  height: 32px;
  width: 300px;
  margin-top: 10px;
}

.leave-cancel-close-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  width: 90%;
}
