.resourceTrackingContainer {
  background-color: #ededed;
}

.resource-tracking-container {
  background-color: #ffffff;
  border-radius: 4px;
  margin: 10px;
  padding: 15px;
}

.resource-dropdown {
  width: 150px;
  height: 30px;
  font-size: 18px;
  outline: none;
}

.AddResource-popup-container {
  background-color: white;
  /* width: 650px; */
  min-width: 300px;
  border-radius: 8px;
  margin-top: 20px;
}

.add-resource-form-container {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 15px;
}

.resourceCount {
  /* border: 1px solid #e86020; */
  min-width: 100px;
  height: 40px;
  margin-left: 10px;
  text-align: center;
  border-radius: 6px;
  padding-top: 8px;
}
.assign-resource-form {
  width: 700px;
  flex-wrap: wrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 10;
  padding: 10px;
}

.dropdown-menu button {
  display: block;
  width: 100%;
  background: none;
  border: none;
  text-align: left;
  padding: 8px 12px;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background: #f5f5f5;
}
