.form-btn-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: red;
  margin-bottom: 10px;
  gap: 10px;
}
.success-msg {
  font-size: 24px;
}
.submit-leave-btn {
  color: white;
  height: 35px;
  width: 120px;
  border: 0px none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 18px;
}
.leave-days {
  color: #003480;
  font-size: 22px;
  font-weight: 600;
}
label {
  display: block;
}
@media (max-width: 576px) {
  .form-control {
    width: 100% !important;
  }
}

.form-control {
  width: 400px;
}
.react-datepicker-wrapper {
  display: block;
}
/* .custom-toast-container{
  position: absolute;
  top: 20%;
  z-index: 9999;
} */
.invalid {
  color: #dc3545;
  margin-top: 5px;
  font-size: 16px;
  border: none;
}
.form-check-input:checked {
  background-color: orange;
  border-color: orange;
}
.form-check-input {
  height: 20px;
  width: 20px;
}
/* .leave-form{
  height: 100%;
} */

.leave-form {
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
