.modal-container {
  position: fixed;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  right: 0%;
  top: 10%;
  bottom: 10%;
  box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.2);
  z-index: 100;
  min-width: 400px;
  /* height: fit-content; */
}

@media (max-width: 600px) {
  .modal-container {
    position: fixed;
    border-radius: 10px;
    background-color: white;
    padding: 20px;
    /* height: 600px; */
    left: 5%;
    right: 0%;
    top: 20%;
    bottom: 10%;
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.2);
  }
}

.close-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  /* background-color: blue; */
  margin-left: 15px;
}

.close-btn {
  border: none;
  color: red;
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
}

@media (min-width: 769px) {
  .form-container {
    padding: 10px 50px;
  }
}

.slidingModal-enter {
  transform: translateX(100%);
}

.slidingModal-enter-active {
  transform: translateX(0%);
  transition: transform 0.3s ease-in;
}

.slidingModal-exit {
  transform: translateX(0%);
}

.slidingModal-exit-active {
  transform: translateX(100%);
  transition: transform 0.3s ease-in;
}

.modal-children {
  overflow: auto;
  padding: 10px;
  height: 90%;
}

.modal-children::-webkit-scrollbar {
  display: none;
}

.modal-heading {
  color: #212121;
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  padding-top: 10px;
}
