.skillsListContainer {
  list-style-type: none;
  padding-left: 0px;
  /* background-color: aqua; */
}

.editSkillElement {
  gap: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

/* input {
  height: 40px;
  border: 1px solid #e86020;
  border-radius: 4px;
  padding-left: 5px;
}

input:focus {
  outline: none;
  border: 2px solid #e86020;
} */
select {
  height: 40px;
  width: 100px;
  /* border: 1px solid #e86020; */
  border-radius: 4px;
}

.deleteIcon {
  color: #e86020;
  padding-bottom: 5px;
  font-size: 35px;
}

.deleteButton {
  border: none;
  cursor: pointer;
  font-size: 30px;
  background-color: transparent;
  height: 40px;
  margin-bottom: 12px;
  margin-left: -25px;
  padding-left: 0px;
  width:50px

}

.skillInputError {
  border-left: 10px solid red;
}
