.forgot-div {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 50%;
}

.forgot-password-container {
  padding-left: 5%;
  /* background-color: #e86020; */
}
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.emailVerificationContainer {
  width: 380px;
  height: 425px;
  /* background-color: aliceblue; */
  margin-left: 20%;
}

.forgot-header {
  font-size: 22px;
}

.generateOtp {
  background-color: transparent;
  border: none;
  color: #e86020;
  font-size: 14px;
  font-weight: 700;
}
.errorText {
  color: #fb5801;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin-top: 8px;
}
