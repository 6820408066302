.uploadBtn-2:disabled {
  cursor: not-allowed;
  background-color: #e0e0e0;
  color: #999999;
}

/* .uploadBtn-1:disabled {
  cursor: not-allowed;
  background-color: #e0e0e0;
  color: #999999;
} */
.upload-excel-label {
  background-color: #f45c2c;
  color: white;
  height: 35px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  margin-right: 15px;
  border: none;
  cursor: pointer;
  font-size: 14px;
}

.upload-excel-label-disabled {
  background-color: #e0e0e0;
  color: #999999;
  height: 35px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  margin-right: 15px;
  border: none;
  cursor: not-allowed;
  font-size: 14px;
}
