.warning-modal-container {
  padding: 20px;
  position: fixed;
  top: 41%;
  left: 40%;
  width: 490px;
  z-index: 5;
  opacity: 1;
  background-color: #ffffff;
  border-radius: 6px;
}
.apply-opacity {
  opacity: 0.3;
}
.leave-management-container {
  min-height: 100vh;
  min-width: 80vw;
}
.widget-card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
}
.widget-card {
  height: 116px;
  width: 171px;
  text-align: center;
  margin: 30px;
  border-radius: 5px;
  background-color: #e860200d;
  color: #000000;
  border: 1px solid #e8602080;
  padding: 20px 8px 0px 12px;
}

.leave-history-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.apply-btn-container {
  align-self: flex-end;
  margin-right: 40px;
}

.apply-leave-btn {
  background-color: #00adee;
  color: white;
  height: 35px;
  width: 120px;
  border: 0px none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 18px;
  margin-right: 30px;
}

@media (max-width: 600px) {
  .widget-card {
    margin: 15px;
  }
  .warning-modal-container {
    position: absolute;
    padding: 25px;
    left: 1%;
    right: 0%;
    top: 30%;
    bottom: 27%;
    margin: 20px;
    height: 198px;
    width: 407px;
  }
}
.leave-header-section {
  background-color: rgb(192, 214, 224);
  width: 100%;
  height: 80px;
  padding: 20px;
  font-size: 25px;
  font-weight: 600;
  color: #003480;
}

@media (min-width: 769px) {
  .form-container {
    padding: 10px 50px;
  }
}

@media (min-width: 768px) {
  .leave-history-container {
    width: 98%;
    margin-left: 20px;
  }
}

.leave-status {
  color: #000000;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
