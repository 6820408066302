.btn-tabs {
  background-color: transparent;
  border: 0px none;
  height: 35px;
  color: #212121;
  font-size: 16px;
  font-weight: 400;
  margin-right: 50px;
}

.active-tab {
  color: #e86020;
  border-bottom: 3px solid #e86020;
  font-weight: 600;
}
