.otp-input-div {
  display: flex;
  width: 320px;
}
.otp-input {
  /* width: calc(100% / 6); */
  margin-right: 16px;
  border: 1px solid #e86020;
  height: 40px;
  width: 40px;
  border-radius: 5px;
  color: #000000;
  font-size: 16px;
}
.otp-div {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 50%;
}
.card {
  background-color: #ffffff;
  width: 700px;
}
.otp-btn-div {
  margin-bottom: 10px;
}
.security-icon {
  padding-top: 10px;
  height: 100px;
  width: 100px;
  color: #0f2445;
}
.resend-btn {
  text-decoration: underline;
  border: none;
  color: #f45c2c;
  background-color: transparent;
}

.otp-header {
  font-size: 18px;
}
.email-bold {
  font-weight: 700;
}

.timer {
  color: #64748b;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: end;
  width: 100%;
  /* padding-right: 60px; */
  width: 320px;
}

.optScreen-container {
  height: 100vh;
  padding-left: 5%;
}

.optCard-container {
  width: 380px;
  height: 425px;
  /* background-color: aliceblue; */
  margin-left: 20%;
}

.error-text {
  color: #fb5801;
  font-size: 14px;
  margin-top: 10px;
}
