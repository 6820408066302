.expenditure-form {
  width: 450px;
  padding: 15px;
  border-radius: 8px;
  background-color: white;
}

.table-container {
  background-color: #ffffff;
  margin: 10px 10px;
  padding: 20px 0px 1px 0px;
  border-radius: 6px;
}

.bill-image {
  width: 250px;
  height: 250px;
  margin-top: 15px;
}
.input-elements {
  width: 100%;
  height: 40px;
  outline: none;
  border-radius: 10px;
  padding-left: 5px;
  border: 1px solid grey;
}

.upload-bill-label {
  background-color: #00adee;
  color: white;
  height: 35px;
  width: fit-content;
  border: 0px none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  font-weight: 600;
  margin-top: 10px;
  padding: 6px;
  margin-right: 15px;
  font-size: 14px;
}
.highlight-error {
  border-left: 10px solid red;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  outline: none;
}
.expendtiture-form-input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  outline: none;
  border: 1px solid grey;
}
.submit-btn:disabled {
  cursor: not-allowed;
  background-color: #e0e0e0;
  color: #999999;
}
