.login-page-container {
  min-width: 375px;
  height: 100vh;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 8%;
}

.login-page-image {
  background-color: transparent;
  width: 10%;
}

.login-card {
  background-color: white;
  border-radius: 5px;
  width: 380px;
  min-height: 425px;
  /* margin-left: 10%; */
  padding: 0px 20px 20px 20px;
  box-sizing: border-box;
}

.company-logo {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  margin-top: 10px;
}

.labels {
  margin-top: 15px;
  margin-bottom: 5px;
}

.input-element {
  width: 100%;
  outline: none;
  height: 40px;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding-left: 5px;
  font-size: 16px;
}

.login-button {
  margin-top: 5px;
  height: 36px;
  width: 350px;
  background-color: #043c84;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  color: aliceblue;
  margin-top: 30px;
  cursor: pointer;
}

.err-msg-display {
  color: red;
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .login-page-image {
    display: none;
  }
  .login-card {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .login-button {
    max-width: 260px;
  }
  .login-page-container {
    width: 100vw;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
}

@media (min-width: 768px) and (max-width: 1012px) {
  .login-card {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .login-page-container {
    width: 100vw;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }
}

input::placeholder {
  font-size: 12px;
}
.box-shadow {
  box-shadow: 0 22px 18px rgba(0, 0, 0, 2);
}

@media screen and (min-width: 768px) {
  .login-card {
    margin-left: 5%;
    margin-right: 10%;
    flex-shrink: 1;
    flex-grow: 0;
  }
  .login-page-image {
    width: 50%;
    height: 60%;
  }
}

.invalid-feedback {
  /* margin-top: 5px;
  margin-bottom: 0px; */
}

.is-invalid {
  border: 1px solid red;
  height: 40px;
  border-radius: 5px;
}

.loader {
  width: 100%;
  height: 6px;
  background: #004643;
  border-radius: 50px;
  overflow: hidden;
}

.inner_loader {
  width: 50%;
  height: 100%;
  background: red;
  border-radius: 0px;
  animation: moveLeftRight 1.5s ease-in-out infinite;
}

@keyframes moveLeftRight {
  0% {
    transform: translateX(calc(-100% + 10px));
  }

  50% {
    transform: translateX(calc(200px - 10px));
  }

  100% {
    transform: translateX(calc(-100% + 10px));
  }
}

.show-checkbox {
  height: 25px;
  width: 48px;
}

.checkbox-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.password-container {
  position: relative;
  margin-top: 30px;
}

.eyeicon {
  position: absolute;
  top: 30%;
  right: 30px;
  cursor: pointer;
  color: #62707c;
}

.forgot-pswd {
  border: none;
  cursor: pointer;
  font-size: 12px;
  background-color: transparent;
  color: #e86020;
  font-weight: 500;
  margin-top: 15px;
}
.forgot-pswd:hover {
  text-decoration: underline;
  color: #f45c2c;
}
.forgot-btn-div {
  margin: top 0.5rem;
  background-color: white;
}

.welcomeMessage {
  color: #212121;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
}

.subtext {
  color: #233747;
  font-size: 14px;
}
