.avatar{
    height: 20vh;
    width: 15vw;
    border-radius: 50%;
    object-fit: cover;

}
.upload-submit-btn-container{
    gap: 5%;
    display: flex;
    margin-right: 10px;
    margin-top: 10px;

}