.reset-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 20%;
  /* margin-left: 200px; */
  /* background-color: #fb5801; */
}

.card {
  display: flex;
  flex-direction: column;
}

.reset-btn-div {
  margin-bottom: 10px;
}

.reset-input-width {
  width: 100% !important;
}

.hide-form {
  display: none;
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkboxElement {
  display: none;
  height: 15px;
  width: 15px;
  padding-top: 30px;
}

.checkbox-label {
  font-size: 14px;
  line-height: 15px;
}
/*
.checkboxElement:checked {
  background-color: #fb5801;
} */

/* input[type="checkbox"] {
  display: none;
} */

/* Style the custom checkbox */
input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid #999;
  border-radius: 3px;
  margin-right: 5px;
}

/* Change color when checked */
input[type="checkbox"]:checked + label:before {
  background-color: #fb5801; /* Your custom color */
  border-color: #fb5801; /* Change border color if needed */
  content: "\2713"; /* Unicode character for checkmark */
  display: inline-block;
  color: #ffffff; /* Color of the checkmark */
  font-size: 16px; /* Adjust the size of the checkmark */
  line-height: 15px; /* Center the checkmark */
  font-weight: 800;
}

.reset-card-container {
  width: 380px;
  /* height: 425px; */
  /* background-color: aliceblue; */
}

.submit-button-container {
  width: 100%;
}
