.page-header-container {
  background-color: #ffffff;
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  color: #212121;
  height: 64px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
