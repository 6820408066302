.extra-work-container {
  /* margin: 20px; */
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.datepicker-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
}
.date-container {
  margin-top: 20px;
}
.select-lead {
  width: 283px;
  height: 56px;
  border: 1px solid gray;
}
.extra-work-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.label-elements {
  font-size: 18px !important;
}

.apply-reimbursement-container {
  width: 700px;
  display: flex;
  flex-wrap: wrap;
}
