.slidingModal-enter {
    transform: translateX(100%);
  }
  
  .slidingModal-enter-active {
    transform: translateX(0%);
    transition: transform 0.3s ease-in;
  }
  
  .slidingModal-exit {
    transform: translateX(0%);
  }
  
  .slidingModal-exit-active {
    transform: translateX(100%);
    transition: transform 0.3s ease-in;
  }
  
  