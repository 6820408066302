.payslipPage-container {
  background-color: #eff2f3;
  width: 100vw;
}

.date-inputs-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.payslip-container {
  min-height: 100%;
  max-height: 100vh;
  max-width: 100vw;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
}

.payslip-div-container {
  width: 794px;
  padding: 13px;
  padding-left: 60px;
}

.payslip-header {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 100%;
}

.payslip-card {
  height: 115px;
  width: 260px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px 0px 15px;
  margin: 0px 10px 10px 0px;
}

.payslip-card p {
  padding: 0px;
  margin-bottom: 0px;
}

.month-card {
  width: 65.71px;
  height: 68.06px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.month-bg {
  background-color: #e86020;
  width: 100%;
  height: 50%;
  border-radius: 5px 5px 0px 0px;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selectElement {
  width: 183px;
  height: 40px;
  padding: 0px, 12px, 0px, 12px;
  border-radius: 4px;
  border: 1px;
  margin-right: 15px;
  font-size: 14px;
  border: 1px solid #000000;
  background-color: #ffffff;
  outline: none;
}

.download-btn {
  background-color: transparent;
  border: none;
  color: #fb5801;
}

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 30px;
  padding-right: 15px;
  width: 100%;
}

.payslip-logo {
  width: 198px;
  height: 55px;
  margin: 0;
}

.company-email {
  display: block;
  font-size: 17px;
}

.company-name {
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  margin-top: 30px;
  color: #f1592a;
}

.main-container {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
  justify-content: flex-end;
}

.react-print-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  padding-right: 15px;
  padding-top: 30px;
}

.payslip-failure {
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  padding-top: 20px;
}

.table-head {
  background-color: #cdcdcd;
  border: 1px solid black;
  padding: 6px;
  font-weight: 900;
  font-weight: bold;
  margin-top: 20px;
}

.salary-slip {
  width: 769px !important;
}

.empDetail {
  text-align: left;
  border: 2px solid black;
  border-collapse: collapse;
  width: 720px;
}

.head {
  margin: 10px;
  margin-bottom: 50px;
  width: 100%;
}

.companyName {
  text-align: right;
  font-size: 25px;
  font-weight: bold;
}

.table-border-bottom {
  border-bottom: 1px solid;
}

.table-border-right {
  border-right: 1px solid;
}

.myBackground {
  padding-top: 10px;
  text-align: left;
  border: 1px solid black;
  height: 40px;
}

.myAlign {
  text-align: center;
  border-right: 1px solid black;
}

.myTotalBackground {
  padding-top: 10px;
  text-align: left;
  background-color: #ebf1de;
  border-spacing: 0px;
}

.align-4 {
  width: 25%;
  float: left;
}

.tail {
  margin-top: 35px;
}

.align-2 {
  margin-top: 25px;
  width: 50%;
  float: left;
}

.border-center {
  text-align: center;
}

.border-center th,
.border-center td {
  border: 1px solid black;
}

.error-payslip-img {
  width: 50%;
}

.payslip-header {
  width: 100%;
  height: 80px;
  padding: 20px;
}

th {
  padding-left: 18.5px;
}

td {
  padding-left: 13.5px;
}

tr {
  padding-bottom: 10px;
}

.personal-info {
  border: 2px solid black;
  border-bottom: none;
  width: 720px;
}

/* The switch - the box around the slider */
.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 1.5em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1em;
  width: 1em;
  border-radius: 20px;
  left: 0.27em;
  bottom: 0.25em;
  background-color: #adb5bd;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #007bff;
  border: 1px solid #007bff;
}

input:focus + .slider {
  box-shadow: 0 0 1px #007bff;
}

input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}

@media print {
  /* Apply margin-top only for printed content */
  body {
    margin-top: 100px; /* Adjust the margin size as needed */
  }
}

.payslip-page-container{
  margin: 10px;
  border-radius: 4px;
  height:88%
}