.not-found-img {
  width: 600px;
  height: 600px;
  background-color: transparent;
}

@media (max-width: 767px) {
  .not-found-img {
    width: 300px;
  }
}

.not-found-container {
  height: 90vh;
  width: 80vw;
}
