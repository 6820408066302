.upload-pdf-label {
  color: white;
  height: 35px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  padding: 10px;
  margin-right: 15px;
  width: fit-content;
  font-size: 16px;
}

.highlight-error {
  border-left: 10px solid red;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 100%;
  height: 35px;
  border-radius: 5px;
  outline: none;
}

.pdf-form-input {
  width: 100%;
  height: 35px;
  border-radius: 5px;
  outline: none;
  border: 1px solid grey;
}

.btns-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
