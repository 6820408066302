
.failure-img{
    width:400px;
    margin-bottom: 25px;
}

.failure-container{
    height:80vh;
    width:85vw;
    
}

.retry-btn{
    background-color: #00ADEE;
    color:white;
    height:35px;
    width:100px;
    border: 0px none;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
}

@media (max-width:767px){
    .failure-img{
        width:280px;
        margin-bottom: 25px;
    }
}