.nav-item-icon {
  font-size: 24px;
  margin-right: 25px;
}

/* .nav-item-container p{
  font-weight: bold;
} */

.nav-item-container {
  height: 35px;
  padding: 10px;
  color: white;
  margin-bottom: 10px;
}

.nav-item-container:hover {
  /* background-color: #00ADEE; */
  /* background-color: #e8e3e3; */
  background-color: #ffffff80;
  color: black;
}

.active-nav {
  /* background-color: #e8e3e3; */
  background-color: #ffffff80;
  color: black;
  border-left: 5px solid White;
  /* height: 48px; */
}

.remove-line-nav-item {
  text-decoration: none;
  margin-bottom: 0px;
}
