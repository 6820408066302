.work-anniversary-container {
    min-height: 368px;
    width: 380px;
    border-radius: 12px;
    margin-top: 10px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
  }
  
  .work-anniversary-heading {
    font-size: 16px;
    color: #161e54;
    margin: 15px;
    font-weight: 800;
    line-height: 24px;
  }
  
  .work-anniversary-card {
    width: 98%;
    height: 64px;
    font-size: 14px;
    border-radius: 6px;
    padding: 5px;
    color: #303030;
    border: 1px solid #e0e0e0;
    list-style-type: none;
    margin: 0px auto 10px auto;
  }
  
  .work-anniversaries-list-content-loader {
    width: 90%;
    border-radius: 8px;
    margin-top: 2rem;
    height: 400px;
  }
  
  .work-anniversaries-list {
    overflow-y: auto;
    height: 258px;
    margin-top: 0px;
    margin-left: 0px;
    padding-left: 0px;
  }
  
  .work-anniversaries-list::-webkit-scrollbar {
    display: none;
  }
  
  .details {
    font-size: 12px;
    color: #686868;
  }
  
  .container-bottom-section {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    height: 39px;
    text-align: center;
    padding: 10px;
    border: 1px solid #e0e0e0;
    color: #ff5151;
    font-weight: 600;
    font-size: 14px;
  }
  
  .container-bottom-section:hover {
    cursor: pointer;
  }
  
  .name-heading {
    color: #303030;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
  }
  
  .screenName {
    margin: 10px 0px 0px 0px;
  }
  