.settings-form {
  padding: 15px;
}

.colors-inputs {
  width: 500px;
  outline: none;
  border-radius: 3px;
  border: 1px solid grey;
  height: 35px;
  margin-right: 15px;
}

.labels {
  margin-top: 15px;
}

.uploadImageContainer {
  display: flex;
  flex-direction: row;
}

.organizationLogo {
  width: 200px;
}
.settings-form {
  width: 100%;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  margin-right: 50px;
}

.settings-input-container {
  margin: 0px 10px 5px 0px;
}

.settings-page-container {
  min-height: 88%;
  margin: 10px 10px;
  border-radius: 6px;
  background-color: #ffffff;
}

.info-icon {
  font-size: 18px;
}
