.main-container {
  /* min-height: 90vh; */
  width: 100vw;
  background-color: #ededed;
}

.dashboard-card {
  width: 188px;
  height: 140px;
  top: 156px;
  padding: 12px;
  border-radius: 10px;
  gap: 16px;
  background-color: #e7f1ff;
  margin-top: 8px;
}

.welcome-details-container {
  margin-top: 10px;
  margin-right: 10px;
  padding: 15px;
  /* background-image: linear-gradient(to right, #003e84, #00adee); */
  background-color: #d9d9d9;
  /* background-image: linear-gradient(to right , #848484 , white); */
  border-radius: 10px;
  color: white;
}

@media screen and (max-width: 568px) {
  .welcome-details-container {
    width: 300px;
  }
}

.details-container {
  height: 300px;
  width: 500px;
  border-radius: 8px;
  margin: 10px 10px 10px 10px;
}

.remove-underline {
  text-decoration: none;
}

.events-card {
  height: 150px;
  width: 190px;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
}

.card-heading {
  color: #212121;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.card-count {
  color: #161e54;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.dashboard-main-container {
  /* box-shadow: 0px 1px 11px 0px #00000040; */
  border-radius: 8px;
  padding: 15px;
  min-height: 88%;
  background-color: #ffffff;
  margin: 10px;
}
