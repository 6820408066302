.profile-info-container div {
    display: flex;
    width: 850px;
  }
  
  .profile-info-container p {
    color: black;
    font-weight: 600;
  }
  
  /* .profile-info-container label {
    width: 500px;
    color: #78829d;
    font-weight: 500;
  } */
  
  .edit-profile-info-container {
    display: flex;
    flex-wrap: wrap;
    /* background-color: #78829D; */
    width: 100%;
  }
  
  .upload-image-section {
    margin: 10px;
    padding: 15px;
    border-radius: 10px;
    width: 500px;
  }
  
  .avatar {
    height: 200px;
    width: 200px;
    border-radius: 50%;
  }
  
  .upload-image-btn {
    display: block;
    margin-top: 20px;
  }
  
  @media screen and (max-width: 767px) {
    .upload-image-section {
      width: 90%;
    }
  }
  
  .edit-account-details {
    width: 800px;
    padding: 15px;
    margin: 10px;
    border-radius: 10px;
  }
  
  .input-fileds {
    width: 300px;
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid grey;
    margin-bottom: 15px;
  }
  
  .form-inputs-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .edit-profile-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .edit-profile-container input,
  .edit-profile-container select {
    width: 300px;
    height: 35px;
    margin-right: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    outline: none;
  }
  
  .upload-image-label {
    background-color: #00adee;
    color: white;
    border-radius: 5px;
    height: 35px;
    width: 100px;
    text-align: center;
    padding: 5px;
    box-sizing: border-box;
    /* margin-top: 15px; */
    font-weight: 600;
    cursor: pointer;
  }
  
  .upload-submit-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 15px;
  }
  
  .highlight-input-error {
    border-left: 10px solid red;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    border-right: 1px solid grey;
    width: 260px;
    height: 35px;
    border-radius: 5px;
    outline: none;
  }
  
  .profile-pic-delete {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .save-btn:disabled {
    cursor: not-allowed;
    background-color: #e0e0e0;
    color: #999999;
  }
  
  .editIcon {
    background-color: transparent;
    border: 0px none;
    color: #e86020;
  }
  
  .editIcon :hover {
    cursor: pointer;
  }