.password-inputs {
  height: 30px;
  width: 250px;
  outline: none;
  border-radius: 3px;
  border: 1px solid grey;
  margin-top: 5px;
  margin-bottom: 10px;
}

.password-error {
  border-left: 10px solid red;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 250px;
  height: 35px;
  border-radius: 5px;
  outline: none;
}

.select-input {
  width: 250px;
  height: 35px;
  border-radius: 5px;
}
