/* .data-table {
  /* padding-top: 100px; */
/* margin-right: 10%; */
/* } */

.popup-overlay-reason {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content-reason {
  background-color: white;
  padding: 25px 25px 25px 25px;
  border-radius: 8px;
  width: 500px;
  height: "auto";
}

@media (max-width: 767px) {
  .popup-content-reason {
    min-width: 350px;
    margin-right: 10%;
    margin-left: 10%;
    height: fit-content;
  }
}

.reason-label {
  display: block;
  margin-bottom: 10px;
}

.reason-input {
  outline: none;
  height: 35px;
  width: 400px;
  border: 1px solid grey;
  border-radius: 5px;
}

@media (max-width: 767px) {
  .reason-input {
    width: 300px;
    border-radius: 5px;
    outline: none;
  }
}

.submit-reason-btn {
  background-color: #00adee;
  border: 0px none;
  height: 35px;
  width: 100px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  border: 0px none;
  display: block;
  margin-top: 10px;
}

.close-btn {
  background-color: transparent;
  border: 0px none;
}

.ag-grid-table {
  padding-top: 50px;
}

.approve-btn {
  width: 100px;
  height: 30px;
  border: 0px none;
  border-radius: 5px;
  text-align: center;
  background-color: #00adee;
  color: white;
  font-size: 16px;
}

.reject-btn {
  width: 100px;
  height: 30px;
  border: 0px none;
  border-radius: 5px;
  text-align: center;
  background-color: red;
  color: white;
  font-size: 16px;
}

.leaves-req-hist-container {
  width: 100vw;
  background-color: #ededed;
}

.page-content-container {
  background-color: #ffffff;
  margin: 10px;
  box-shadow: 0px 1px 11px 0px #8d8d8d40;
  border-radius: 4px;
  padding-bottom: 20px;
}

.leaves-history-button {
  background-color: #00adee;
  color: white;
  border: 0px none;
  height: 35px;
  border-radius: 8px;
}

.leave-managment-header {
  background-color: rgb(192, 214, 224);
  width: 100%;
  height: 80px;
  padding: 20px;
  font-size: 25px;
  font-weight: 600;
  color: #003480;
}

.leaves-tab-container {
  padding: 10px;
}

.leave-active-tab {
  padding: 5px;
  /* color: #e86020; */
  background-color: white;
  margin-right: 50px;
  /* font-weight: 600; */
  border-width: 0; /* Reset border width on all sides */
  /* border-bottom: 3px solid #e86020; */
}

.leave-tab {
  padding: 5px;
  margin-right: 50px;
  background-color: white;
  border: 0px none;
  color: #212121;
  font-size: 16px;
  /* font-weight: 600; */
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.reason-input-error {
  border-left: 10px solid red;
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  width: 400px;
  height: 35px;
  border-radius: 5px;
  outline: none;
}
