
@media (min-width: 768px) {
  .-employee-form-container {
    width: 659px;
    max-height: 900px;
    display: flex;
    gap: 18px;
    flex-wrap: wrap;
    z-index: 2;
  }
}

@media screen and (max-width: 800px) {
  .-employee-form-container {
    width: 300px;
    height: 800px;
    /* overflow-y: auto; */
  }
}
.-emp-submit-btn{
  display: flex;
  justify-content:flex-end ;
}