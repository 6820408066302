.extra-div-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.extra-work-history-div {
  margin-top: 20px;
}

.reimbursement-tabs-container {
  padding: 10px;
  /* display: flex;
  justify-content: space-between; */
  background-color: #ffffff;
  margin: 10px;
  border-radius: 6px;
  height: 87%;
}

.reimbursement-active-tab {
  padding: 5px;
  margin-right: 50px;
  font-weight: 500;
  border: none;
  border-bottom: 3px solid #e86020;
  background-color: transparent;
  color: #e86020;
}

.reimbursement-tab {
  margin-right: 50px;
  font-weight: 500;
  border: none;
  background-color: transparent;
}
