.salary-page {
  width: 100vw;
  background-color: #ededed;
}

.salary-container {
  margin: 20px;
}
.salary-table-container {
  /* width: 100%; */
  margin-top: 5px;
  height: 80vh;
}
.calculation-modal {
  position: absolute;
  top: 10%;
  left: 38%;
  right: 30%;
  bottom: 20%;
  z-index: 9999;
  height: 724px;
  width: 600px;
}
.employee-salary-div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 91px;
  padding-top: 37px;
}
h4 {
  width: 300px;
}
.generate-btn {
  margin-right: 10px;
}

.submit-cancel-btn {
  margin-top: 15px;
}
.cancel-input {
  height: 32px;
  width: 300px;
  margin-top: 10px;
}
.leave-cancel-close-icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 10px;
  width: 90%;
}

.generating-payroll {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.loading-payroll {
  margin-left: 5px;
  margin-right: 5px;
}

.salary-management-table-container {
  background-color: #ffffff;
  margin: 10px 10px;
  padding: 10px;
  border-radius: 6px;
}
