.add-holiday-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0px;
  gap: 10px;
}

.add-holidays-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
