.policy-card {
  min-width: 220px;
  width: 171px;
  height: 116px;
  border: 1px solid #e8602080;
  padding-right: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.policies {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 10px;
}

.cardText {
  margin-top: 10px;
  font-size: 16px;
  margin-left: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.pdf-container {
  width: 100vw;
  height: 100vh;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

.pdf-heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.policyPage-container {
  background-color: #ffffff;
  margin: 10px 10px;
  border-radius: 6px;
  height: 85%;
}

.pdfImage {
  width: 70px;
  margin-top: 15px;
  margin-left: 5px;
}
