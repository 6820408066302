/* .small-text{
    font-size: 16px;
}
.form-div-container{
    width: 100%;
}
.input-width{
    width: 100%;
}
 .label-elements{
    font-size: 18px !important;
  } */

.month-input-label {
  font-size: 16px;
  height: 41px;
  width: 357px;
  border-radius: 4px;
  border: 1px solid #000000;
  color: #000000;
  font-weight: 500;
  outline: none;
}
