.Noskillsmsgcontainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.noSkillsmsg {
  font-size: 18px;
  font-weight: 600;
}

.skills-container {
  position: relative;
  height: 95%;
}

.Addskill-popup-content {
  background-color: white;
  border-radius: 8px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.add-skill-btn-container {
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-end;
}

.addSkillsBtn {
  height: 35px;
  width: 35px;
  /* margin-left: 95%; */
  background-color: transparent;
  border: none;
  color: #e86020;
  font-size: 20px;
}

.skills-submit-btn-container {
  margin-right: auto;
  margin-top: 10px;
  width: 100%;
}

.skills-list-container {
  list-style-type: none;
  width: 90%;
  height: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
}

.skills-list-item {
  width: 500px;
  margin-right: 50px;
  margin-bottom: 30px;
}

.skills-card {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
}
.skill-name-element {
  width: 150px;
}

.progress {
  width: 100%;
  height: 10px;
}

.add-skill-error {
  width: 100%;
  margin-top: 10px;
}

.skill-edit-btn {
  background-color: transparent;
  border: none;
}

.rating-input-ele {
  width: 100px;
  margin-left: 10px;
  font-size: 18px;
  outline: none;
}

.skill-inputs {
  width: 100%;
  height: 35px;
  font-size: 18px;
  outline: none;
}
